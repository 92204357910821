<template>
  <div class="progress-bar" :style="styleBar">
    <div class="loader-box" :style="customStyle"></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const styleBar = ref({
  height: '5px',
  display: 'block', // none
});
const customStyle = ref('');
</script>

<style scoped>
.progress-bar {
  position: relative;
  overflow: hidden;
}

.loader-box {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(45deg, #b6b5ff, #ff9797);
  left: 0%;
}

.loader-box {
  left: -100%;
  animation: load 3s linear infinite;
}

@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>
