<template>
  <BaseModal v-show="showModal" padding="px-6 py-3" @close="showModal = false">
    <template #header>
      <span class="">יצירה ויבוא</span>
    </template>
    <template #body>
      <div v-if="showLoading" class="px-6 py-3">
        <LoadingProgress />
      </div>
      <div v-else class="flex flex-col gap-4 max-w-sm">
        <BaseInput
          v-model="newSchoolData.name"
          class="w-full"
          :placeholder="t('headers.schoolName')"></BaseInput>
        <BaseInput
          v-model="newSchoolData.semel"
          class="w-full"
          :placeholder="t('headers.institutionSymbol')">
          <template #prefix>
            <SearchIcon :size="16" />
          </template>
        </BaseInput>
        <BaseInput
          v-model="newSchoolData.code"
          :disabled="true"
          class="w-full"
          :placeholder="t('headers.institutionCode')"></BaseInput>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end items-center gap-2">
        <BaseButton color="light" @click="showModal = false">בטל</BaseButton>
        <BaseButton @click="createSchool">המשך</BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { BaseModal, BaseInput, BaseButton } from '@/';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSchoolStore } from '../school-store';
import { SearchIcon } from 'lucide-vue-next';
import swal from 'sweetalert';

// import { searchSchools } from '/@/app/services/system';
import { addSchool } from '/@/app/services/facilities';
import LoadingProgress from '/@/views/playground/partials/LoadingProgress.vue';

// const { onSuccess: onSearchSchools } = searchSchools();
//
// onSearchSchools(data => {
//   console.log(data);
// });

const showModal = ref(false);
const showLoading = ref(false);
const newSchoolData = ref({});
const schoolStore = useSchoolStore();

const open = () => {
  showModal.value = true;
};

defineExpose({ open });

const createSchool = () => {
  const { onError, onSuccess } = addSchool(newSchoolData.value);

  showLoading.value = true;
  onSuccess(() => {
    showLoading.value = false;
    showModal.value = false;
    swal({
      title: 'Synced',
      text: 'All data is good',
      icon: 'success',
    });
    schoolStore.init().then(() => {});
  });

  onError(error => {
    showLoading.value = false;
    showModal.value = false;
    swal({
      title: 'Error',
      text: error.message,
      icon: 'error',
    });
  });
};
const messages = {
  he: {
    headers: {
      schoolName: 'שם בית ספר',
      location: 'מיקום',
      institutionSymbol: 'סמל מוסד',
      institutionCode: 'קוד מוסד',
      id: 'מספר זיהוי',
      students: 'תלמידים',
      teachers: 'מורים',
      grades: 'ציונים',
      groups: 'קבוצות',
    },
  },
};
const { t } = useI18n({ messages });
</script>

<style scoped></style>
